/* eslint-disable react/no-unescaped-entities */
import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';

import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const PageTemplate = css`
  .site-main {
    padding-bottom: 4vw;
  }
`;

const About: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>About</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header css={[outer, SiteHeader]}>
        <div css={inner}>
          <SiteNav />
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <article className="post page" css={[PostFull, NoImage]}>
          <PostFullHeader>
            <PostFullTitle>About</PostFullTitle>
          </PostFullHeader>

          <PostFullContent className="post-full-content">
            <div className="post-content">
              <p>
                We’re a small, fast-growing team of machine learning engineers, software engineers,
                designers and customer development ninjas, working to remove the final layer of the
                language barrier that speech presents. We're starting by making the world's video
                content watchable in any language. We translate videos with an automatically
                generated voice that preserves the character and emotion of the original voice.
                Powered by a proprietary neural text-to-speech engine, we've developed a tool that
                harnesses the strengths of both artificial and human intelligence.
              </p>
              <p>
                Curious to see and hear what our translated videos are like? Find out more on our{' '}
                <a href="https://www.papercup.com/">website</a>, and connect with us on{' '}
                <a href="https://twitter.com/PapercupAI">Twitter</a>,{' '}
                <a href="https://www.facebook.com/PapercupAI/">Facebook</a> and{' '}
                <a href="https://www.linkedin.com/company/papercup-ai/about/">LinkedIn</a>. If
                you’re interested to join us (we’re almost always hiring!), check out our job
                openings <a href="https://papercup.jobs.personio.de/">here</a>. Finally,
                if you would like to get in touch, you can reach us by{' '}
                <a href={'mailto:' + CONFIG.contactEmail} target="_blank" rel="noopener noreferrer">
                  email
                </a>
                .
              </p>
            </div>
          </PostFullContent>
        </article>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;
